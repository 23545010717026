import React from 'react'
import PropTypes from 'prop-types'
import { Container, Section } from '../Common'
import MainBlock from './MainBlock'
import SliderBlock from './SliderBlock'

const ImageBlockSection = ({
    slider,
    title,
    headline,
    description,
    sliderContent,
    image,
}) => {
    return (
        <Section>
            <Container fullBleed>
                <div className="banner-block">
                    <div className="banner-block__content">
                        {slider ? (
                            <SliderBlock
                                title={title}
                                headline={headline}
                                description={description}
                                sliderContent={sliderContent}
                            />
                        ) : (
                            <MainBlock
                                title={title}
                                headline={headline}
                                description={description}
                                image={image}
                            />
                        )}
                    </div>
                    <div className="banner-block__tr-rectangle"></div>
                    <div className="banner-block__bl-rectangle"></div>
                </div>
            </Container>
        </Section>
    )
}

ImageBlockSection.propTypes = {
    slider: PropTypes.bool,
    sliderContent: PropTypes.array,
    title: PropTypes.string,
    headline: PropTypes.string,
    description: PropTypes.string,
}

export { ImageBlockSection }
