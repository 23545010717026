import React, { useState, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'

import MainBlock from './MainBlock'

SwiperCore.use([Pagination, Navigation, Autoplay])

const SliderBlock = ({ sliderContent, title, headline, description }) => {
    const [slideChange, setSlideChange] = useState(false)
    const [isVideoPlaying, setVideoPlaying] = useState(false)
    const videoRef1 = useRef(null)
    const videoRef2 = useRef(null)
    const swiperRef = useRef(null)

    const videoToggle = state => setVideoPlaying(state)

    const settings = {
        autoplay: !isVideoPlaying,
        navigation: {
            nextEl: '.banner-block__swiper-button-next',
            prevEl: '.banner-block__swiper-button-prev',
        },
    }

    const pauseVideo = () => {
        videoRef1.current.pause()
        videoRef2.current.pause()

        setVideoPlaying(false)
    }
    useLayoutEffect(() => {
        if (isVideoPlaying) swiperRef.current.swiper.autoplay.stop()
        else swiperRef.current.swiper.autoplay.start()
    }, [isVideoPlaying])

    return (
        <>
            <Swiper
                slidesPerView={1}
                loop
                autoplay={settings.autoplay}
                pagination
                navigation={settings.navigation}
                onSlideChange={() => {
                    pauseVideo()
                    setSlideChange(!slideChange)
                }}
                ref={swiperRef}
            >
                {sliderContent &&
                    sliderContent.map((item, i) => {
                        const content = {
                            blueBoxTitle: item.blockblueboxtitle,
                            description: item.blockdescription,
                            headline: item.blocktitle,
                            image:
                                item.blockimage?.sourceUrlSharp.childImageSharp
                                    .fluid,
                            posterImage: item?.blockposterimage?.sourceUrl,
                            videoUrl: item.blockvideo?.mediaItemUrl,
                        }
                        // console.log(content)
                        return (
                            <SwiperSlide>
                                <MainBlock
                                    image={content.image}
                                    video={content.videoUrl}
                                    title={content.blueBoxTitle}
                                    description={content.description}
                                    headline={content.headline}
                                    posterImage={content.posterImage}
                                    videoToggle={videoToggle}
                                    ref={i > 1 ? videoRef2 : videoRef1}
                                />
                            </SwiperSlide>
                        )
                    })}
            </Swiper>
            <div className="banner-block__slider-controls">
                <div className="banner-block__slider-buttons">
                    <div className="banner-block__swiper-button-prev">
                        <div className="icon icon--arrow-left"></div>
                    </div>
                    <div className="banner-block__swiper-button-next">
                        <div className="icon icon--arrow-right"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

SliderBlock.propTypes = {
    sliderContent: PropTypes.array.isRequired,
    title: PropTypes.string,
    headline: PropTypes.string,
    description: PropTypes.string,
}

export default SliderBlock
