import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import htmr from 'htmr'
import VizSensor from 'react-visibility-sensor'

const MainBlock = React.forwardRef(
    (
        {
            image,
            title,
            headline,
            description,
            video,
            posterImage,
            videoToggle,
        },
        ref
    ) => {
        const [isVisible, setVisible] = useState(null)
        const [isPlaying, setPlaying] = useState(false)

        function toggleVideo() {
            let currentState = !isPlaying
            setPlaying(currentState)
            videoToggle(currentState)
        }

        return (
            <>
                <div className="banner-block__image-container">
                    {image ? <Img fluid={image} /> : ''}

                    {video ? (
                        <VizSensor onChange={visible => setVisible(visible)}>
                            <video
                                onPlay={() => toggleVideo()}
                                onPause={() => toggleVideo()}
                                controls
                                preload="auto"
                                poster={posterImage}
                                ref={ref}
                            >
                                <source src={video} type="video/mp4" />
                            </video>
                        </VizSensor>
                    ) : (
                        ''
                    )}
                </div>
                <div
                    className="banner-block__info"
                    style={{
                        visibility: `${isPlaying ? 'hidden' : 'visible'}`,
                    }}
                >
                    <div
                        className="banner-block__info-title"
                        style={{
                            visibility: `${title ? 'visible' : 'hidden'}`,
                        }}
                    >
                        {title ? htmr(title) : ''}
                    </div>
                    <h2 className="banner-block__info-headline">
                        {htmr(headline)}
                    </h2>
                    <p className="banner-block__info-description">
                        {htmr(description)}
                    </p>
                    <div className="banner-block__info-cta"></div>
                </div>
            </>
        )
    }
)

MainBlock.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    headline: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object.isRequired,
}

export default MainBlock
